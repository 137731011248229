import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import Title from '../../components/Title';
import ServiveItem from './ServiceItem';
import * as ROUTES from '../../constants/routes';
import { listServices } from '../../redux/serviceDuck';
import { useTranslation } from 'react-i18next';
import './Service.css';

const Service = () => {

    const services = useSelector(store => store.service.services);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(listServices());
    }, [dispatch]);

    return (
        <Grid container>
            <Title title={t('servicesTitle')} route={ROUTES.HOME_PAGE} />
            <Grid container className='container-service'>
                {services.map((service, index) => (
                    <ServiveItem service={service} key={index} />
                ))}
            </Grid>
        </Grid>
    );
};

export default Service;