import { firestore } from '../services/Firebase';
import { doc, getDoc } from "firebase/firestore"; 

// constants
const initialState = {
    loading: false,
    propertyData: {
        propertyImages: []
    },
    activeImage: 0
};

// types
const LOADING_PROPERTY = 'LOADING_PROPERTY';
const PROPERTY_SUCCESS = 'PROPERTY_SUCCESS';
const PROPERTY_ERROR = 'PROPERTY_ERROR';
const DATA_PROPERTY_SUCCESS = 'DATA_PROPERTY_SUCCESS';
const CHANGE_ACTIVE_IMAGE_PROPERTY = 'CHANGE_ACTIVE_IMAGE_PROPERTY';


// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_PROPERTY:
            return { ...state, loading: true };
        case PROPERTY_SUCCESS:
            return { ...state, property: action.payload, loading: false };
        case DATA_PROPERTY_SUCCESS:
            return { ...state, propertyData: action.payload, loading: false };
        case CHANGE_ACTIVE_IMAGE_PROPERTY:
            return { ...state, activeImage: action.payload };
        case PROPERTY_ERROR:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state }
    }
};

//actions

export const getPropertyById = (propertyId) => async (dispatch) => {
    dispatch({ type: LOADING_PROPERTY });
    try {
        const docRef = doc(firestore, "properties", propertyId);
        const property = await getDoc(docRef);
        if (property.exists()) {
            const propertyFeatures = property.data()['property-features'];
            const communityFeatures = property.data()['community-features'];
            const featuresArray = propertyFeatures.concat(communityFeatures);

            const propertyData = {
                key: property.id,
                propertyImages: property.data()['property-images'],
                descriptionEn: property.data()['description-en'],
                descriptionEs: property.data()['description-es'],
                propertyId: property.data()['property-id'],
                virtualTourLink: property.data()['virtual-tour-link'],
                // additionalDetails: property.data()['additional-details'],
                features: featuresArray,
                categoryNameEn: property.data()['category']['value-en'],
                categoryNameEs: property.data()['category']['value-es'],
                price: property.data()['price'],
                name: property.data()['name'],
                purposeEn: property.data()['purpose-en'] ?? [],
                purposeEs: property.data()['purpose-es'] ?? [],
                propertyPriceCurrency: property.data()['property-price-currency'] ?? 'crc',
                facebookLink: property.data()['facebook-link'],
            };
            dispatch({
                type: DATA_PROPERTY_SUCCESS,
                payload: propertyData,
            });
        }
    } catch (error) {
        dispatch({
            type: PROPERTY_ERROR,
            payload: 'Error al listar la propiedad'
        })
    }
}

export const changeActiveImages = (index) => (dispatch) => {
    dispatch({
        type: CHANGE_ACTIVE_IMAGE_PROPERTY,
        payload: index
    });
};