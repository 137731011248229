import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Snackbar } from '@material-ui/core';
import { InsertLink } from '@material-ui/icons';
import { formatNumber } from '../../constants/utils';
import ImageGallery from "react-image-gallery";
import ReactPlayer from 'react-player'
import './PropertyImages.css';
import { useTranslation } from 'react-i18next';

const PropertyImages = props => {
    const propertyData = useSelector(store => store.property.propertyData);
    const activeImage = useSelector(store => store.property.activeImage);
    const [snackOpen, setSnackOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [playVideo, setPlayVideo] = useState(false);
    const { t } = useTranslation();


    const getShareLink = () => {
        const url = window.location.href;
        var input = document.createElement('input');
        input.setAttribute('value', url);
        document.getElementById('root').append(input);
        input.select();
        document.execCommand('copy');
        input.remove();
        setSnackOpen(true);
    };

    const handleCloseSnackbar = (e, reason) => {
        if (reason === 'clickaway') return;
        setSnackOpen(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        let galleryItems = []

        if (propertyData.virtualTourLink) {
            galleryItems.push({
                original: 'https://firebasestorage.googleapis.com/v0/b/bienes-raices-dev.appspot.com/o/property%2Fvideothum.png?alt=media&token=73e6188e-23f4-4e7e-9d37-2540572c7593',
                thumbnail: 'https://firebasestorage.googleapis.com/v0/b/bienes-raices-dev.appspot.com/o/property%2Fvideothum.png?alt=media&token=73e6188e-23f4-4e7e-9d37-2540572c7593',
                originalClass: 'carousel-property-image',
                thumbnailClass: 'carousel-property-thumbnail',
                originalHeight: '100%',
                originalWidth: '100%',
                thumbnailHeight: '100px',
                renderItem: (item) => <ReactPlayer
                    playing={playVideo}
                    controls width={'100%'}
                    height={'100%'}
                    url={propertyData.virtualTourLink}
                    onPause={() => {
                        setPlayVideo(false)
                    }}
                    onPlay={() => setPlayVideo(true)}
                />
            })
        }

        galleryItems = galleryItems.concat(propertyData.propertyImages.map((img) => ({
            original: img.url,
            thumbnail: img.thumbnail,
            originalClass: 'carousel-property-image',
            thumbnailClass: 'carousel-property-thumbnail',
            originalHeight: '100%',
            originalWidth: '100%',
            thumbnailHeight: '100px',
        })));

        setItems(galleryItems)
    }, [propertyData, playVideo]);



    return propertyData.propertyImages ? (
        <Grid item xs={12} id={activeImage} className='carousel-property-container'>
            <ImageGallery
                showPlayButton={false}
                showIndex={true}
                infinite={false}
                lazyLoad={true}
                autoPlay={false}
                items={items}
                onBeforeSlide={(index) => {
                    setPlayVideo(false)
                }}
            />
            <div className='property-detail-share' onClick={() => getShareLink()}>
                <InsertLink style={{ marginRight: 12 }} />
                SHARE
            </div>
            <div className='property-detail-price'>
                {t('priceUpTitle')}: {propertyData.propertyPriceCurrency === 'usd' ? 'USD' : 'CRC'} {formatNumber(propertyData.price)}
            </div>

            <Snackbar open={snackOpen}
                autoHideDuration={4000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                message={<span>Link Copied</span>}
                className='property-detail-share-snackbar'
            />
        </Grid>
    ) : null;
};

export default PropertyImages;