import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, Snackbar } from '@material-ui/core';
import { Mail, Call, WhatsApp } from '@material-ui/icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactMarkdown from 'react-markdown';
import ShowMoreText from 'react-show-more-text';
import { useTranslation } from 'react-i18next';
import './Agent.css';

const AgentItem = ({ agent, index }) => {

    const activeAgent = useSelector(store => store.agent.activeAgent);
    const [expand, setExpand] = useState(true);
    const onExpand = () => setExpand(!expand);
    const [snackOpen, setSnackOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const experienceEs = agent.experienceEs.replaceAll('~', '\n');
    const experienceEn = agent.experienceEn.replaceAll('~', '\n');
    console.log(experienceEs)

    const handleCloseSnackbar = (e, reason) => {
        if (reason === 'clickaway') return;
        setSnackOpen(false);
    };

    return (
        <Grid item xs={11} sm={10}
            className={`${index === activeAgent && 'active-agent'}`}
            style={{ display: index === activeAgent ? 'block' : 'none' }}
        >
            <Grid container>
                <Grid item xs={12} md={6}>
                    <LazyLoadImage
                        alt='' src={agent.profileImage}
                        className='agent-img-item'
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography className='agent-name'>
                        {agent.name}
                    </Typography>
                    <Typography className='agent-title-info'>
                        {t('experienceTitle')}
                    </Typography>
                    <ShowMoreText
                        lines={7}
                        more={t('showMoreTitle')}
                        less={t('showLessTitle')}
                        onClick={onExpand}
                        expanded={expand}
                        className='agent-info'
                    >
                        <ReactMarkdown>
                            {
                                i18n.language.includes('es') ?
                                experienceEs :
                                    experienceEn
                            }
                        </ReactMarkdown>
                    </ShowMoreText>
                    {(agent.idiomsEn || agent.idiomsEs) &&
                        <>
                            <Typography className='agent-title-info'>
                                {t('languagesTitle')}
                            </Typography>
                            <Typography className='agent-info'>
                                {i18n.language.includes('es') ? agent.idiomsEs : agent.idiomsEn}
                            </Typography>
                        </>
                    }
                    <Typography className='agent-title-info'>
                        {t('contactTitle')}
                    </Typography>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {agent.completeCode &&
                            <div className='agent-contact'>
                                {t('codeTitle')}
                                <p>{agent.completeCode}</p>
                            </div>
                        }
                        {agent.contact.email &&
                            <div className='agent-contact'>
                                <Mail />
                                <div>
                                    {agent.contact.email.map((email, index) => (
                                        <p key={index}>{email}</p>
                                    ))}
                                </div>
                            </div>
                        }
                        {agent.contact.whatsapp &&
                            <div className='agent-contact'>
                                <WhatsApp />
                                <p>{agent.contact.whatsapp}</p>
                            </div>
                        }
                        {agent.contact['phone-number'] &&
                            <div className='agent-contact'>
                                <Call />
                                <p>{agent.contact['phone-number']}</p>
                            </div>
                        }
                    </div>
                    <Snackbar open={snackOpen}
                        autoHideDuration={4000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        onClose={handleCloseSnackbar}
                        message={<span>Link Copied</span>}
                        className='property-detail-share-snackbar'
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AgentItem;