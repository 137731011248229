import axios from 'axios';

// constantes
const initialState = {
    isSended: false,
    loading: false,
    error: null
};

// types
const SEND_MAIL = 'SEND_MAIL';
const CANCEL_IS_SENDED = 'CANCEL_IS_SENDED';
const LOADING_CONTACT_US = 'LOADING_CONTACT_US';
const ERROR_CONTACT_US = 'ERROR_CONTACT_US';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_CONTACT_US:
            return { ...state, loading: true, error: null };
        case SEND_MAIL:
            return { ...state, loading: false, error: null, isSended: true };
        case CANCEL_IS_SENDED:
            return { ...state, isSended: false };
        case ERROR_CONTACT_US:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

// actions

export const sendMail = (mail) => async (dispatch) => {
    dispatch({ type: LOADING_CONTACT_US });
    try {
        const url = `${process.env.REACT_APP_CLOUDFUNCTIONS_URL}/contactUsMail`;
        await axios.post(url, {
            firstName: mail.firstName,
            lastName: mail.lastName,
            email: mail.email,
            phoneNumber: mail.phoneNumber,
            message: mail.message,
            subscribe: mail.subscribe
        });
        dispatch({ type: SEND_MAIL });
    } catch (error) {
        dispatch({
            type: ERROR_CONTACT_US,
            payload: 'Error al enviar el correo'
        })
    }
};

export const isSendedCancel = () => (dispatch) => {
    dispatch({
        type: CANCEL_IS_SENDED
    })
}