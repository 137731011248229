import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme, createTheme } from '@material-ui/core/styles';
import {
    List, Drawer, AppBar, Toolbar, IconButton,
    ListItem, ListItemText, Container, ListItemIcon,
    Button, Popper, Grow, Paper, ClickAwayListener,
    MenuList, MenuItem
} from '@material-ui/core';
import {
    ChevronRight, ChevronLeft, Menu
} from '@material-ui/icons';
import logo from '../../assets/logos/logo.png';
import CssBaseline from '@material-ui/core/CssBaseline';
import * as ROUTES from '../../constants/routes';
import './Navbar.css';
import { useTranslation } from 'react-i18next';
// import { ReactComponent as InvestmentCreditIcon } from '../../assets/Icons/credit-card.svg';
import { ReactComponent as ServicesIcon } from '../../assets/Icons/services.svg';
import { ReactComponent as AgentIcon } from '../../assets/Icons/agent.svg';
import { ReactComponent as AboutUsIcon } from '../../assets/Icons/aboutUs.svg';
import { ReactComponent as ContactIcon } from '../../assets/Icons/contact.svg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import LanguageIcon from '@material-ui/icons/Language';

const drawerWidth = 300;

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 900,
            md: 1000,
            lg: 1280,
            xl: 1920,
        },
    },
})

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
    appBar: {
        width: '100vw',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#000',
        height: 80
        // [theme.breakpoints.up('xs')]: {
        //     height: 80,
        // },
        // [theme.breakpoints.up('sm')]: {
        //     height: 100,
        // },
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        position: 'absolute',
        [theme.breakpoints.up('xs')]: {
            marginRight: 0,
            display: 'flex'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('xs')]: {
            display: 'flex'
        },
        backgroundColor: '#000'
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
        display: 'none'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        height: 80,
        // [theme.breakpoints.up('xs')]: {
        //     height: 80,
        // },
        // [theme.breakpoints.up('md')]: {
        //     height: 100,
        // },
        borderBottom: '1px solid #FFF'
    },
    content: {
        width: '100%',
        marginTop: 80
        // [theme.breakpoints.up('xs')]: {
        //     marginTop: 80
        // },
        // [theme.breakpoints.up('md')]: {
        //     marginTop: 100
        // }
    },
    link: {
        textDecoration: 'none',
    },

    navbarDisplayFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 0,
        [theme.breakpoints.up('xs')]: {
            margin: '0',
        },
        [theme.breakpoints.up('md')]: {
            margin: '0 20px',
        },
        [theme.breakpoints.up('lg')]: {
            margin: '0 50px',
        },
    },
    navDisplayFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    navDisplayContact: {
        height: 36,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 10,
        fontWeight: 300,
        fontSize: 14,
        lineHeight: '16px',
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    logo: {
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('xs')]: {
            width: '100%',
            justifyContent: 'flex-start',
            marginLeft: 'calc(60% - 40px)',

        },
        [theme.breakpoints.up('sm')]: {
            width: 'min-content',
            display: 'flex',
            marginLeft: '0%',
            justifyContent: 'flex-start'
        },
    },
    contactToolbar: {
        display: 'grid',
        fontWeight: 300,
        fontSize: 14,
        lineHeight: '16px',
        color: '#FFF',
        height: '80%'
    },
    contactToolbarDiv: {
        display: 'flex',
        alignItems: 'center'
    }
}));

const Navbar = props => {
    const { children } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const onDrawerOpen = () => setOpen(true);

    const onDrawerClose = () => setOpen(false);

    const navLinks = [
        // { title: t('investmentCreditTitle'), path: ROUTES.INVESTMENT_CREDIT, Icon: InvestmentCreditIcon },
        { title: t('servicesTitle'), path: ROUTES.SERVICES, Icon: ServicesIcon },
        { title: t('agentsTitle'), path: ROUTES.AGENTS, Icon: AgentIcon },
        { title: t('aboutUsTitle'), path: ROUTES.ABOUT_US, Icon: AboutUsIcon },
        { title: t('contactUsTitle'), path: ROUTES.CONTACT_US, Icon: ContactIcon }
    ];

    const anchorRef = React.useRef(null);
    const anchorLangRef = React.useRef(null);
    const [openToggle, setOpenToggle] = React.useState(false);
    const [openLangToggle, setOpenLangToggle] = React.useState(false);
    const handleToggle = () => setOpenToggle((prevOpen) => !prevOpen);
    const handleLangToggle = () => setOpenLangToggle((prevOpen) => !prevOpen);
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenToggle(false);
    };
    const handleLangClose = (event) => {
        if (anchorLangRef.current && anchorLangRef.current.contains(event.target)) {
            return;
        }
        setOpenLangToggle(false);
    };

    const changeLang = (lang) => {
        i18n.changeLanguage(lang)
        setOpenLangToggle(false);
    }
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenToggle(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(openToggle);
    const prevLangOpen = React.useRef(openLangToggle);
    React.useEffect(() => {
        if (prevOpen.current === true && openToggle === false) {
            anchorRef.current.focus();
        }

        if (prevLangOpen.current === true && openLangToggle === false) {
            anchorLangRef.current.focus();
        }

        prevOpen.current = openToggle;
        prevLangOpen.current = openLangToggle;
    }, [openToggle, openLangToggle]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position='fixed' className={clsx(classes.appBar, { [classes.appBarShift]: open })} >
                {/* <Container className={classes.navDisplayContact}>
                    <PhoneOutlined style={{ marginRight: 15 }} /> +506 9030 0231
                    <MailOutlined style={{ margin: '0 15 0 25' }} /> info@decobienes.com
                </Container> */}
                <Toolbar style={{ height: '100%', width: '100%' }}>
                    <IconButton
                        color='inherit'
                        aria-label='open drawer'
                        onClick={onDrawerOpen}
                        edge='start'
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,

                        })}
                    >
                        <Menu />
                    </IconButton>
                    <Container className={classes.navbarDisplayFlex}>
                        {!open &&
                            <div className={classes.logo}>
                                <img style={{ height: '80px%', width: '80px'}} src={logo} alt='' />
                            </div>
                        }
                        <List
                            component='nav'
                            aria-labelledby='main navigation'
                            className={classes.navDisplayFlex}
                        >
                            <ListItem button className="navbar-list-item" style={{ width: 'fit-content' }}>
                                <Button
                                    ref={anchorRef}
                                    aria-controls={openToggle ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                    className='properties-item'
                                    style={{ padding: 0 }}
                                >
                                    {t('propertiesTitle')}
                                    {openToggle
                                        ? <ArrowDropUpIcon style={{ margin: '0 0 3px 5px' }} />
                                        : <ArrowDropDownIcon style={{ margin: '0 0 3px 5px' }} />
                                    }
                                </Button>
                                <Popper open={openToggle}
                                    anchorEl={anchorRef.current}
                                    role={undefined} transition
                                    disablePortal
                                    style={{ width: '100%' }}
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                        >
                                            <Paper style={{ borderRadius: 0 }}>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList autoFocusItem={openToggle} id="menu-list-grow"
                                                        onKeyDown={handleListKeyDown}
                                                        className="menu-list-property-filter"
                                                    >
                                                        <MenuItem onClick={handleClose}>
                                                            <a href="?purpose=FOR%2520SALE/#properties">{t("saleTitle")}</a>
                                                        </MenuItem>
                                                        <MenuItem onClick={handleClose} style={{ borderTop: '1px solid #FFF' }}>
                                                            <a href="?purpose=FOR%2520RENT/#properties">{t("rentTitle")}</a>
                                                        </MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </ListItem>
                            {navLinks.map(({ title, path }) => (
                                path.includes('#')
                                    ?
                                    <a href={path} key={title} className={classes.link}>
                                        <ListItem button className='navbar-list-item'>
                                            <ListItemText primary={title} />
                                        </ListItem>
                                    </a>
                                    :
                                    <Link to={path} key={title} className={classes.link}>
                                        <ListItem button className='navbar-list-item'>
                                            <ListItemText primary={title} />
                                        </ListItem>
                                    </Link>
                            ))}
                        </List>
                    </Container>
                    <Button
                                ref={anchorLangRef}
                                aria-controls={openLangToggle ? 'menu-lang-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={handleLangToggle}
                                className='properties-item'
                                startIcon={<LanguageIcon />}
                                style={{ padding: 0 }}
                            >
                                {i18n.language.includes('es') ? 'ES' : 'EN'}
                                {openLangToggle
                                    ? <ArrowDropUpIcon style={{ margin: '0 0 3px 5px' }} />
                                    : <ArrowDropDownIcon style={{ margin: '0 0 3px 5px' }} />
                                }
                            </Button>
                            <Popper open={openLangToggle}
                                anchorEl={anchorLangRef.current}
                                role={undefined} transition
                                disablePortal
                                style={{ width: '80px' }}
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                        <Paper style={{ borderRadius: 0 }}>
                                            <ClickAwayListener onClickAway={handleLangClose}>
                                                <MenuList autoFocusItem={openLangToggle} id="menu-lang-list-grow"
                                                    onKeyDown={handleListKeyDown}
                                                    className="menu-list-property-filter"
                                                >
                                                    <MenuItem onClick={() => changeLang('en')}>
                                                        EN
                                                    </MenuItem>
                                                    <MenuItem onClick={() => changeLang('es')} style={{ borderTop: '1px solid #FFF' }}>
                                                        ES
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                </Toolbar>
            </AppBar>
            <Drawer
                variant='permanent'
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    {/* <Container className={classes.contactToolbar}>
                        <div className={classes.contactToolbarDiv}>
                            <PhoneOutlined style={{ marginRight: 15 }} /> +506 9030 0231
                        </div>
                        <div className={classes.contactToolbarDiv}>
                            <MailOutlined style={{ marginRight: 15 }} /> info@decobienes.com
                        </div>
                    </Container> */}
                    <IconButton onClick={onDrawerClose} style={{ color: '#FFF' }} size='small'>
                        {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
                    </IconButton>
                </div>
                <List onClick={() => onDrawerClose()}>
                    {navLinks.map(({ title, path, Icon }) => (
                        path.includes('#')
                            ?
                            <a href={path} key={title} className={classes.link}>
                                <ListItem button className='sidebar-list-item'>
                                    <ListItemIcon><i><Icon /></i></ListItemIcon>
                                    <ListItemText primary={title} />
                                </ListItem>
                            </a>
                            :
                            <Link to={path} key={title} className={classes.link}>
                                <ListItem button className='sidebar-list-item'>
                                    <ListItemIcon><i><Icon /></i></ListItemIcon>
                                    <ListItemText primary={title} />
                                </ListItem>
                            </Link>
                    ))}
                </List>
            </Drawer>
            <main className={classes.content}>
                {children}
            </main>
        </div>
    );

}

export default Navbar;