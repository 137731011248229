import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';


const ServiveItem = ({ service }) => {
    const { i18n } = useTranslation();

    return (
        <Grid item xs={12} md={4} style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='service-card'>
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <LazyLoadImage
                            alt='' src={service.image}
                            className='service-image'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ padding: 16 }}>
                        <Typography className='service-name'>
                            {i18n.language.includes('es') ?  service.nameEs : service.nameEn}
                        </Typography>
                        {(i18n.language.includes('es') ? service.detailsEs : service.detailsEn).map((detail, index) => (
                            <p className="service-detail" key={index}>
                                • {detail}
                            </p>
                        ))}
                        {(service.descriptionEs || service.descriptionEn) &&
                            <p className='service-description'>
                                {i18n.language.includes('es') ? service.descriptionEs : service.descriptionEn}
                            </p>
                        }
                    </Grid>
                </Grid>
            </div>
        </Grid>
    )
};

export default ServiveItem;