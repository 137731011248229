import React from 'react';
import Navbar from '../../components/Navbar';
import Carousel from '../../components/Carousel';
import PropertyFilter from '../PropertyFilter';
import ContactUs from '../ContactUs';

const HomePage = () => {

    return (
        <Navbar>
            <Carousel />
            <PropertyFilter />
            <ContactUs />
        </Navbar>
    );
};

export default HomePage;