import React from 'react';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { connectStateResults } from 'react-instantsearch-dom';
import CardItem from '../../Property/PropertyItem';

const PropertyResults = connectStateResults((props) => {

    const { searching, searchResults } = props;

    return (
        <div>
            {searching
                ?
                <Grid container justifyContent='center'>
                    <CircularProgress style={{ color: '#231F20' }} />
                </Grid>
                :
                <Grid container justifyContent='center' style={{ marginTop: '3%' }}>
                    <Grid item xs={12}>
                        <Hits hits={!searchResults ? [] : searchResults.hits} />
                    </Grid>
                </Grid>
            }
        </div>
    );

});

const Hits = ({ hits }) => {

    return (
        <div>
            {hits.length > 0 ?
                <Grid container style={{ marginTop: 20 }}>
                    {hits.map((hit, index) => (
                        <CardItem key={index} property={hit} />
                    ))}
                </Grid>
                :
                <Typography color='inherit' style={{ textAlign: 'center' }}>
                    No results found.
                </Typography>
            }
        </div>
    );
};

export default PropertyResults;