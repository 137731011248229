import React, { useState } from 'react';
import ShowMoreText from 'react-show-more-text';
import { Paper, Typography, Button } from '@material-ui/core';
import { formatNumber } from '../../constants/utils';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';

const PropertyInfo = ({ property }) => {

    const [expand, setExpand] = useState(false);

    const onExpand = () => setExpand(!expand);
    const { t, i18n } = useTranslation();
    return property ? (
        <>
            <Paper className='carousel-property-card'>
                <Typography className='carousel-property-card-title'>
                    {property.name}
                </Typography>
                <ShowMoreText
                    lines={2}
                    more={t("showMoreTitle")}
                    less={t("showLessTitle")}
                    onClick={onExpand}
                    expanded={expand}
                    className='carousel-property-card-subtitle'
                >
                    <ReactMarkdown>
                        {i18n.language.includes('es') ? property.descriptionEs : property.descriptionEn}
                    </ReactMarkdown>
                </ShowMoreText>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography className='carousel-property-card-title'>
                    {t("priceTitle")}: {property.propertyPriceCurrency === 'usd' ? 'USD' : 'CRC' } {formatNumber(property.price)}
                    </Typography>
                    <Link to={ROUTES.PROPERTY.replace(':id', property.key)}
                        style={{ textDecoration: 'none' }}>
                        <Button variant='contained' className='carousel-property-card-button'>
                        {t("viewPropertyTitle")}
                        </Button>
                    </Link>
                </div>
            </Paper>
            <Link to={ROUTES.PROPERTY.replace(':id', property.key)}
                style={{ textDecoration: 'none' }}>
                <Button variant='contained' className='carousel-property-card-button-mobile'>
                {t("viewPropertyTitle")}
                </Button>
            </Link>
        </>
    ) : (
            <div className='div-carousel-message'>
                <label className='div-carousel-message-label'>
                    {t("carousellTitle")}
                </label>
            </div>
        );
};

export default PropertyInfo;