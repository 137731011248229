import React from 'react';
import { SortBy } from 'react-instantsearch-dom';
import './SortBy.css';
import { useTranslation } from 'react-i18next';

const PropertySortBy = () => {
    const { t } = useTranslation();

    return (
        <SortBy
            className='container-option'
            defaultRefinement='property'
            items={[
                {
                    label: t("featuredTitle"),
                    value: 'property',
                },
                {
                    label: t("priceAscTitle"),
                    value: 'property_price_asc',
                },
                {
                    label: t("priceDescTitle"),
                    value: 'property_price_desc',
                },
            ]}
        />
    )
}

export default PropertySortBy;