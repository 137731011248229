import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import Title from '../../components/Title';
import coverImage from '../../assets/Images/coverImage.png';
import coverLogo from '../../assets/Images/coverLogo.png';
import * as ROUTES from '../../constants/routes';
import './AboutUs.css';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { getAbout } from '../../redux/aboutUsDuck';

const AboutUs = () => {

    const aboutUsInfo = useSelector(store => store.aboutUs.aboutUsInfo);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!aboutUsInfo.loaded)
            dispatch(getAbout());
    }, [dispatch, aboutUsInfo]);

    return (
        <Grid container>
            <Title title={t('aboutUsTitle')} route={ROUTES.HOME_PAGE} />
            <Grid item xs={12} className='image-about-us-conatiner'>
                <img alt='' src={coverImage} className='image-about-us-image' />
                <div className='circular-div'>
                    <img className='circular-square' src={coverLogo} alt='' />
                </div>
            </Grid>
            <Grid container style={{ padding: '0 5%' }}>
                <Grid item xs={12} md={4} className='grid-1'>
                    <p className='tittle'>{t('visionTitle')}</p>
                    <p className='about-description'>
                        {i18n.language.includes('es') ? aboutUsInfo.visionEs : aboutUsInfo.visionEn}
                    </p>
                    <p className='tittle'>{t('missionTitle')}</p>
                    <p className='about-description'>
                        {i18n.language.includes('es') ? aboutUsInfo.misionEs : aboutUsInfo.misionEn}
                    </p>
                </Grid>
                <Grid item xs={12} md={8} className='grid-2'>
                    <p className='tittle'>{t('historyTitle')}</p>
                    {(i18n.language.includes('es') ? aboutUsInfo.historyEs : aboutUsInfo.historyEn).map((history, index) => (
                        <ReactMarkdown key={index} className='about-description'>
                            {history.replace('~', '\n')}
                        </ReactMarkdown>
                    ))}
                </Grid>
                {/* <Grid item xs={12} md={4} className='grid-3'>
                    <p className='tittle'>Process to send filled permits and contracts.</p>
                    <div className='number-container'>
                        <img alt='' src={firstStep} className='contract-step-icon' />
                        <p className='about-description'>
                            In this first step you have to choose which document
                            you need, and download it.
                        </p>
                    </div>
                    <LibraryBooksIcon className='download-icon' />
                    <p className='about-description'>
                        Download the letter of permits for the agents here.
                    </p>
                    <div className='dowload-container'>
                        <div className='button-container'>
                            <a href={filePath} download='logo.png' className='btn-black'>
                                DOWNLOAD
                            </a>
                        </div>
                    </div>
                    <div className='number-container'>
                        <LibraryBooksIcon className='download-icon' />
                        <p className='about-description'>
                            In this first step you have to choose which document you need,
                            and download it.
                        </p>
                    </div>
                    <div className='dowload-container'>
                        <div className='button-container'>
                            <a href={filePath} download='logo.png' className='btn-black'>
                                DOWNLOAD
                            </a>
                        </div>
                    </div>
                    <div className='number-container'>
                        <img alt='' src={secondStep} className='contract-step-icon' />
                        <p className='about-description'>
                            The second step would be fill up the documents.
                        </p>
                    </div>
                    <div className='number-container'>
                        <img alt='' src={thirdStep} className='contract-step-icon' />
                        <p className='about-description'>
                            Once you have filled up the documents you can send it
                            to: <label className='link'>info@decobienes.com</label>
                        </p>
                    </div>
                </Grid> */}
            </Grid>
        </Grid>
    );
};

export default AboutUs;