import React from 'react';
import { SearchBox } from 'react-instantsearch-dom';
import { useTranslation } from 'react-i18next';
import './PropertySearch.css';

const PropertyFilter = () => {
    const { t } = useTranslation();
    return (
        <SearchBox searchAsYouType={false}
            translations={{
                placeholder: t('searchPropertyTitle')
            }}
            submit={
                <p>{t('searchTitle')}</p>
            }
        />
    );
}

export default PropertyFilter;