import React from 'react';
import { ClearRefinements } from 'react-instantsearch-dom';
import { useTranslation } from 'react-i18next';
import {
    ReactComponent as ClearFilterIcon
} from '../../../assets/Icons/clear-filter.svg';

const ClearFilters = () => {
    const { t } = useTranslation();
    return (
        <ClearRefinements
            translations={{
                reset: (
                    <>
                        <ClearFilterIcon />
                        {t('clearFiltersTitle')}
                    </>
                ),
            }}
        />
    )
}

export default ClearFilters;