import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#FFF',
    },
}));

const Loading = () => {

    const classes = useStyles();
    const agentLoading = useSelector(store => store.agent.loading);
    const invesmentCreditLoading = useSelector(store => store.invesmentCredit.loading);
    const serviceLoading = useSelector(store => store.service.loading);
    const propertyLoading = useSelector(store => store.property.loading);

    const active = () => {
        return !!(agentLoading || invesmentCreditLoading || serviceLoading || propertyLoading);
    };

    return (
        <Backdrop className={classes.backdrop} open={active()}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default Loading;