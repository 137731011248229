import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const TitleModule = ({ title, route }) => {

    return (
        <Container style={{
            width: '100vw', maxWidth: '100vw', height: 80, backgroundColor: '#000',
            color: '#FFF', display: 'flex', alignItems: 'center', 
            position: 'fixed', zIndex: 100000,
        }}>
            <Link to={route} style={{
                position: 'absolute', textDecoration: 'none',
                color: '#FFF', marginLeft: '3%'
            }}>
                <ArrowBackIcon />
            </Link>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Typography style={{
                    width: '60%', textAlign: 'center',
                    fontWeight: '300', fontSize: '18px', lineHeight: '21px',
                    textTransform: 'uppercase'
                }} noWrap>
                    {title}
                </Typography>
            </div>
        </Container>
    )
}

export default TitleModule;