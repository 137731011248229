import React, { Suspense } from 'react';
import {
  BrowserRouter as Router, Route, Routes
} from 'react-router-dom';
import { Container } from '@material-ui/core';
import Loading from './components/Loading';
import Footer from './components/Footer';
import HomePage from './views/HomePage';
import PropertyDetail from './views/Property';
import InvestmentCredit from './views/InvestmentCredit';
import Agents from './views/Agent';
import AgentDetail from './views/Agent';
import AboutUs from './views/AboutUs';
import Service from './views/Service';
import * as ROUTES from './constants/routes';
import './App.css';

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Container className='app-container'>
        <Loading />
        <Router>
          <Routes>
            <Route element={<HomePage />} path={ROUTES.HOME_PAGE} exact />
            <Route element={<HomePage />} path={ROUTES.HOME_PAGE_SEARCH} />
            <Route element={<PropertyDetail />} path={ROUTES.PROPERTY} exact />
            {/* <Route element={<InvestmentCredit />} path={ROUTES.INVESTMENT_CREDIT} exact /> */}
            <Route element={<Agents />} path={ROUTES.AGENTS} exact />
            <Route element={<AgentDetail />} path={ROUTES.AGENTS_DETAIL} exact />
            <Route element={<AboutUs />} path={ROUTES.ABOUT_US} exact />
            <Route element={<Service />} path={ROUTES.SERVICES} exact />
          </Routes>
        </Router>
        <Footer />
      </Container>
    </Suspense>
  )
};

export default App;
