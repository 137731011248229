import React from 'react';
import { connectStats } from 'react-instantsearch-dom';
import { formatNumber } from '../../../constants/utils';

const ResultsNumberMobile = ({ nbHits,results }) => (
  <div>
    <strong>{formatNumber(nbHits)}</strong> {results}
  </div>
);

export default connectStats(ResultsNumberMobile);
