import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ShowMoreText from 'react-show-more-text';
import { formatNumber } from '../../constants/utils';
import * as ROUTES from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import './PropertyItem.css';

const PropertyItem = ({ property }) => {

    const [expand, setExpand] = useState(false);
    const { t, i18n } = useTranslation();

    const state = () => {
        const propuseEs = property['purpose-es'] ?? [];
        const propuseEn = property['purpose-en'] ?? [];
        const forRent = i18n.language.includes('es') ? propuseEs[0] : propuseEn[0];
        const forSale = i18n.language.includes('es') ? propuseEs[1] : propuseEn[1];
        return `${forRent}${forSale ? (' / ' + forSale) : ''}`;
    };

    const onExpand = () => setExpand(!expand);

    return (
        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='property-card'>
                <Grid container style={{ height: '100%' }}>
                    <Grid item xs={12} sm={4}>
                        <LazyLoadImage
                            alt='' src={property.thumbnail}
                            className='property-image'
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} style={{
                        display: 'flex', flexDirection: 'column',
                        justifyContent: 'space-between',
                        padding: '8px 8px 12px 16px'
                    }}>
                        <Grid container>
                            <Grid item xs={12} md={12} lg={6}>
                                <Typography className='property-name'>
                                    {property.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} className='state-item'>
                                <Typography className='property-state'>
                                    {state()}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 16 }}>
                                <ShowMoreText
                                    lines={5}
                                    more={t('showMoreTitle')}
                                    less={t('showLessTitle')}
                                    keepNewLines={true}
                                    onClick={onExpand}
                                    expanded={expand}
                                    anchorClass='show-more-less-clickable'
                                    className='property-description'
                                >
                                    {i18n.language.includes('es') ? property['description-es'] : property['description-en']}
                                </ShowMoreText>
                            </Grid>
                        </Grid>
                        <Grid container direction='row'
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <Grid item xs={12} md={12} lg={6} style={{ marginTop: 20 }}>
                                <Typography className='property-price'>
                                    {t('priceUpTitle')}: {property['property-price-currency'] === 'usd' ? 'USD' : 'CRC'} {formatNumber(property.price)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} style={{
                                display: 'flex', justifyContent: 'flex-end',
                                marginTop: 20
                            }}>
                                <Link to={ROUTES.PROPERTY.replace(':id', property.objectID)}
                                    style={{ textDecoration: 'none' }}>
                                    <Button variant='outlined' className='property-button'>
                                        {t('viewPropertyTitle')}
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Grid>
    );
};

export default PropertyItem;