import { firestore } from '../services/Firebase/index';
import { collection, query, orderBy, getDocs } from "firebase/firestore";

const initialState = {
    loading: false,
    error: null,
    services: [],
}

const SERVICE_SUCCESS = 'SERVICE_SUCCESS';
const SERVICE_LOADING = 'SERVICE_LOADING';
const SERVICE_ERROR = 'SERVICE_ERROR';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SERVICE_LOADING:
            return { ...state, loading: true, error: null };
        case SERVICE_SUCCESS:
            return { ...state, error: null, services: action.payload, loading: false };
        case SERVICE_ERROR:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
}

export const listServices = () => async (dispatch) => {
    dispatch({ type: SERVICE_LOADING });
    try {
        const results = [];
        const agentsRef = collection(firestore, "services");
            const agentsQuery = query(agentsRef, orderBy('priority'))
        const servicesResult = await getDocs(agentsQuery);
        servicesResult.forEach(item => {
            results.push({
                key: item.id,
                image: item.data().image,
                nameEs: item.data()['name-es'],
                nameEn: item.data()['name-en'],
                detailsEs: item.data()['details-es'] ?? [],
                detailsEn: item.data()['details-en'] ?? [],
                descriptionEs: item.data()['description-es'],
                descriptionEn: item.data()['description-en'],
            });
        });
        dispatch({
            type: SERVICE_SUCCESS,
            payload: results
        });
    } catch (error) {
        console.log('Error:', error);
        dispatch({
            type: SERVICE_ERROR,
            payload: 'No se pudo cargar los servicios, inténtelo nuevamente'
        });
    }
}