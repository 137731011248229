import React, { useRef } from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch, Configure,
    connectStateResults, RefinementList,
    HierarchicalMenu, Panel
} from 'react-instantsearch-dom';
import PropertyResults from './components/PropertyResult';
import {
    PriceSlider, PropertyPagination,
    PropertySearch, ResultsNumberMobile,
    ClearFiltersMobile, SaveFiltersMobile,
    PropertySortBy, ClearFilters
} from './widgets';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import './PropertyFilter.css';
import './PropertyFilter.mobile.css';
import CloseOutlined from '@material-ui/icons/CloseOutlined';

const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_ID,
    process.env.REACT_APP_ALGOLIA_KEY
);

const PropertyFilter = props => {

    const { searchState, createURL, onSearchStateChange } = props;

    const containerRef = useRef(null);
    const headerRef = useRef(null);
    const { t, i18n } = useTranslation();
    const CustomStateResults = connectStateResults(PropertyResults);

    const openFilters = () => {
        document.body.classList.add('filtering');
        window.scrollTo(0, 0);
        window.addEventListener('keyup', onKeyUp);
        window.addEventListener('click', onClick);
    };

    const closeFilters = (notScroll) => {
        document.body.classList.remove('filtering');
        window.removeEventListener('keyup', onKeyUp);
        window.removeEventListener('click', onClick);
        if (notScroll) return;
        containerRef.current.scrollIntoView();
    };

    const onKeyUp = (event) => {
        if (event.key !== 'Escape') return;
        closeFilters();
    };

    const onClick = (event) => {
        if (event.target !== headerRef.current) return;
        closeFilters();
    };

    return (
        <div id="properties">
            <InstantSearch indexName='property' searchClient={searchClient}
                searchState={searchState}
                createURL={createURL}
                onSearchStateChange={onSearchStateChange}
            >
                <Configure
                    attributesToSnippet={[`description-${i18n.language.includes('es') ? 'es' : 'en'}:10`]}
                    snippetEllipsisText='…'
                    removeWordsIfNoResults='allOptional'
                    hitsPerPage={12}
                    filters='is-enabled:true AND active:true'
                />
                <header ref={headerRef} style={{ marginTop: 40 }} className='header-search'>
                    <PropertySearch />
                </header>

                <aside data-layout='mobile'>
                    <button
                        className='filters-button'
                        data-action='open-overlay'
                        onClick={openFilters}
                    >
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 14'>
                            <path
                                d='M15 1H1l5.6 6.3v4.37L9.4 13V7.3z'
                                stroke='#fff'
                                strokeWidth='1.29'
                                fill='none'
                                fillRule='evenodd'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            />
                        </svg>
                        {t('filtersTitle')}
                    </button>
                </aside>


                <main className='container' ref={containerRef}>
                    <div className='container-wrapper'>
                        <section className='container-filters' onKeyUp={onKeyUp}>
                            <div className='container-filter-align'>
                                <div className='container-header' style={{ width: '100%' }}>
                                    <p>{t('filtersTitle')}</p>
                                    <div data-layout='desktop'>
                                        <ClearFilters />
                                    </div>
                                    <div data-layout='mobile' style={{
                                        height: '90px',
                                        marginTop: '10px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-around'
                                    }}>
                                        <IconButton onClick={() => closeFilters(true)} aria-label="delete">
                                            <CloseOutlined />
                                        </IconButton>
                                        <ResultsNumberMobile results={t("resultsTitle")} />
                                        <header className='container-options'>
                                            <PropertySortBy />
                                        </header>
                                    </div>
                                </div>
                            </div>
                            <hr className='property-filter-divider' />
                            <div className='container-body'>
                                <Panel header={t('categoriesTitle')}>
                                    <HierarchicalMenu
                                        attributes={[
                                            `category-name-${i18n.language.includes('es') ? 'es' : 'en'}`,
                                            `sub-categories-${i18n.language.includes('es') ? 'es' : 'en'}`
                                        ]}
                                    />
                                </Panel>
                                <hr className='property-filter-divider' />
                                <Panel header={t('statusTitle')}>
                                    <RefinementList attribute={`purpose-${i18n.language.includes('es') ? 'es' : 'en'}`} />
                                </Panel>
                                <hr className='property-filter-divider' />
                                <Panel header={t('currency')}>
                                    <RefinementList
                                        attribute={`property-price-currency`}
                                        transformItems={(items) => {
                                            return items.map(item => ({
                                                ...item,
                                                label: item.label ? item.label.toUpperCase() : '',
                                            }));
                                        }}
                                    />
                                </Panel>
                                <hr className='property-filter-divider' />
                                <Panel header={t('priceTitle')} className='panel-price'>
                                    <PriceSlider attribute='price' />
                                </Panel>
                                <hr className='property-filter-divider' />
                                <Panel header={t('searchByLocationTitle')}>
                                    <HierarchicalMenu
                                        attributes={[
                                            'country',
                                            'state'
                                        ]}
                                    />
                                </Panel>
                            </div>
                        </section>
                        <footer className='container-filters-footer' data-layout='mobile'>
                            <div className='container-filters-footer-button-wrapper'>
                                <ClearFiltersMobile containerRef={containerRef} />
                            </div>
                            <div className='container-filters-footer-button-wrapper'>
                                <SaveFiltersMobile onClick={() => closeFilters(false)} />
                            </div>
                        </footer>
                    </div>
                    <section className='container-results'>
                        <header className='container-header container-options' data-layout='desktop'>
                            <PropertySortBy />
                        </header>
                        <hr className='property-filter-divider' data-layout='desktop' />
                        <CustomStateResults />
                    </section>
                </main >
                <footer className='container-footer'>
                    <PropertyPagination />
                </footer>
                <hr className='property-filter-divider' id='last-divider'
                    style={{ width: 'calc(100% - 74px)' }}
                />
            </InstantSearch >
        </div >
    );
}

export default PropertyFilter;
