import React from 'react';
import TextField from '@material-ui/core/TextField';
import './TextField.css';


const TextFieldComponent = ({ name, valueInput, row, errorInput,
    callback, InputProps, placeholder = '', className }) => {

    const onChange = event => {
        callback(event);
    };

    return (
        <TextField
            id={`filled-basic ${name}`}
            variant="filled"
            margin="normal"
            required
            fullWidth
            name={name}
            value={valueInput}
            autoComplete={name}
            onChange={onChange}
            className={`input-texfield ${className}`}
            multiline={!!row}
            minRows={row}
            maxRows={row}
            error={!!errorInput}
            helperText={errorInput}
            InputProps={InputProps}
            placeholder={placeholder}
        />
    );
};

export default TextFieldComponent;