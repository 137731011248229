import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Button, MobileStepper, Step } from '@material-ui/core';
import TitleModule from '../../components/Title';
import AgentItem from './AgentItem';
import { ArrowBackIos } from '@material-ui/icons';
import { changeActiveAgent, getAllAgents } from '../../redux/agentDuck';
import * as ROUTES from '../../constants/routes';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Agent.css';

const Agent = () => {
    const { id } = useParams();
    const agents = useSelector(store => store.agent.agents);
    const activeAgent = useSelector(store => store.agent.activeAgent);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getAllAgents());
    }, [dispatch]);

    useEffect(() => {
        if (id != null)
            if (agents != null)
                for (var i = 0; i < agents.length; i++) {
                    if (id === agents[i].key)
                        dispatch(changeActiveAgent(i));
                }
    }, [id, dispatch, agents]);

    const nextAgent = () => {
        const maxIndex = agents.length - 1;
        if (maxIndex > activeAgent)
            dispatch(changeActiveAgent(activeAgent + 1));
        else if (maxIndex > 0)
            dispatch(changeActiveAgent(0));
    };

    const backAgent = () => {
        const maxIndex = agents.length - 1;
        if (0 < activeAgent)
            dispatch(changeActiveAgent(activeAgent - 1));
        else if (maxIndex > 0)
            dispatch(changeActiveAgent(maxIndex));
    };

    return (
        <Grid container justifyContent='center'>
            <TitleModule title={t('agentsTitle')} route={ROUTES.HOME_PAGE} />
            {agents.length > 0 &&
                <Grid container direction='row'
                    justifyContent='center' alignItems='center'
                    className='agent-container-info'
                >
                    {agents.length > 1 &&
                        <Grid item sm={1} data-layout='desktop'>
                            <Button className='agent-carousel-home-back-button'
                                onClick={() => backAgent()}
                            >
                                <ArrowBackIos />
                            </Button>
                        </Grid>
                    }
                    {agents.map((agent, index) => (
                        <AgentItem agent={agent} index={index} key={index} />
                    ))}
                    {agents.length > 1 &&
                        <Grid item sm={1} data-layout='desktop'>
                            <Button className='agent-carousel-home-next-button'
                                onClick={() => nextAgent()}
                            >
                                <ArrowBackIos />
                            </Button>
                        </Grid>
                    }
                    {agents.length > 1 &&
                        <Grid item xs={2} data-layout='mobile-agent'>
                            <Button className='agent-carousel-home-back-button'
                                onClick={() => backAgent()}
                            >
                                <ArrowBackIos />
                            </Button>
                        </Grid>
                    }
                    {agents.length > 1 &&
                        <Grid item xs={8} sm={12} className='agent-carousel-home-stepper-container'>
                            <MobileStepper variant='dots' steps={agents.length} position='static'
                                activeStep={activeAgent} className='agent-carousel-home-stepper'
                            >
                                {agents.map((i, index) => (
                                    <Step key={index} />
                                ))}
                            </MobileStepper>
                        </Grid>
                    }
                    {agents.length > 1 &&
                        <Grid item xs={2} data-layout='mobile-agent'>
                            <Button className='agent-carousel-home-next-button'
                                onClick={() => nextAgent()}
                            >
                                <ArrowBackIos />
                            </Button>
                        </Grid>
                    }
                </Grid>
            }
        </Grid>
    )
}

export default Agent;