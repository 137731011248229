import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Title from '../../components/Title';
import PropertyImages from './PropertyImages';
import PropertyDetail from './PropertyDetail';
import { getPropertyById } from '../../redux/propertyDuck';
import * as ROUTES from '../../constants/routes';

const Property = () => {
    const propertyData = useSelector(store => store.property.propertyData);
    const dispatch = useDispatch();
    const { id } = useParams();
    
    useEffect(() => {
        dispatch(getPropertyById(id));
    }, [dispatch, id]);

    return (
        <Grid container>
            <Title title={propertyData.name} route={ROUTES.HOME_PAGE} />
            <PropertyImages />
            <PropertyDetail />
        </Grid>
    );
}
export default Property;