import { firestore } from '../services/Firebase';
import axios from 'axios';

// constants
const initialState = {
    activeStepInvestor: 0,
    isSendedInvestor: false,
    loadingInvestor: false,
    messageInvestor: null
};

// types
const INVESTOR_CHANGE_STEP = 'INVESTOR_CHANGE_STEP';
const INVESTOR_MESSAGE = 'INVESTOR_MESSAGE';
const INVESTOR_CANCEL_IS_SENDED = 'INVESTOR_CANCEL_IS_SENDED';
const INVESTOR_LOADING = 'INVESTOR_LOADING';
const INVESTOR_ERROR = 'INVESTOR_ERROR';

// reducer
export default function investorReducer(state = initialState, action) {
    switch (action.type) {
        case INVESTOR_LOADING:
            return { ...state, loadingInvestor: true, messageInvestor: null };
        case INVESTOR_CHANGE_STEP:
            return { ...state, activeStepInvestor: action.payload };
        case INVESTOR_MESSAGE:
            return { ...state, messageInvestor: action.payload, activeStepInvestor: 0, loadingInvestor: false, isSended: true };
        case INVESTOR_CANCEL_IS_SENDED:
            return { ...state, isSended: false };
        case INVESTOR_ERROR:
            return { ...state, messageInvestor: action.payload, loadingInvestor: false };
        default:
            return state;
    }
};

export const addDataInvestor = (credit) => async (dispatch) => {
    dispatch({ type: INVESTOR_LOADING });
    try {
        const newCredit = {
            'email': credit.emailInvestor,
            'currency-amount': credit.currencyAmountInvestor,
            'amount': parseFloat(credit.amountInvestor),
            'percent': credit.percent ? credit.percent : 0,
            'mortgage': credit.mortgage ? 'YES' : 'NO',
            'property': credit.property ? 'YES' : 'NO',
            'minimum-term': credit['radio-group'],
        }
        await sendMail(newCredit);
        await firestore.collection('investor-client/').add(newCredit);
        dispatch({
            type: INVESTOR_MESSAGE,
            payload: "Email sent successfully"
        });
    } catch (error) {
        dispatch({
            type: INVESTOR_ERROR,
            payload: "ERROR TO SEND A MESSAGE, TRY AGAIN"
        });
    }
}

const sendMail = async (credit) => {
    const url = `${process.env.REACT_APP_CLOUDFUNCTIONS_URL}/investorMail`;;
    await axios.post(url, {
        email: credit.email,
        amount: credit.amount,
        currency: credit['currency-amount'],
        percent: credit.percent,
        mortgage: credit.mortgage,
        property: credit.property,
        term: credit['minimum-term']
    });
}

export const changeActiveStepInvestor = (step) => (dispatch) => {
    dispatch({
        type: INVESTOR_CHANGE_STEP,
        payload: step
    });
};

export const isInvestorSendedCancel = () => (dispatch) => {
    dispatch({ type: INVESTOR_CANCEL_IS_SENDED });
};