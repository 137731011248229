export const formatNumber = (value) => {
    return Number(value).toLocaleString();
}

export const formatText = (text) => {
    const splitText = text.split("\n");
    var formattedText = '';
    splitText.forEach(paragraph => {
        formattedText += paragraph + '\n';
    });

    return formattedText;
}