import { firestore } from '../services/Firebase';
import { onSnapshot, collection, query, where } from "firebase/firestore";
import Carousel1 from '../assets/Images/carousel1.jpg';
import Carousel2 from '../assets/Images/carousel2.jpg';
import Carousel3 from '../assets/Images/carousel3.jpg';
import Carousel4 from '../assets/Images/carousel4.jpg';
import Carousel5 from '../assets/Images/carousel5.jpg';

// constantes
const initialState = {
    properties: [],
    defaultImages: [],
    activeImage: 0
};
var unsubscribe = null;


// types
const GET_CAROUSEL_SUCCESS = 'GET_CAROUSEL_SUCCESS';
const CHANGE_ACTIVE_IMAGE = 'CHANGE_ACTIVE_IMAGE';
const CAROUSEL_ERROR = 'CAROUSEL_ERROR';
const CAROUSEL_LOADING = 'CAROUSEL_LOADING';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CAROUSEL_LOADING:
            return { ...state, loading: true, error: null };
        case GET_CAROUSEL_SUCCESS:
            return {
                ...state, properties: action.properties,
                defaultImages: action.defaultImages,
                error: null, loading: false
            };
        case CHANGE_ACTIVE_IMAGE:
            return { ...state, activeImage: action.payload };
        case CAROUSEL_ERROR:
            return { ...state, error: action.payload, loading: false };
        default:
            return state;
    }
};

// actions
export const getPropertiesCarousel = () => (dispatch) => {
    dispatch({ type: CAROUSEL_LOADING });
    try {
        if (unsubscribe)
            unsubscribe();
        const propertiesRef = collection(firestore, "properties");
        const propertiesQuery = query(propertiesRef, where('active', '==', true), where('is-header', '==', true), where('is-enabled', '==', true));
        unsubscribe = onSnapshot(propertiesQuery, querySnapshot => {
            dispatch({
                type: GET_CAROUSEL_SUCCESS,
                properties: onCollectionUpdate(querySnapshot),
                defaultImages: getDefaultImages()
            });
        });
    } catch (error) {
        dispatch({
            type: CAROUSEL_ERROR,
            payload: 'Error al cargar lista de propiedades'
        });
    }
};

const onCollectionUpdate = (querySnapshot) => {
    const businessList = [];
    querySnapshot.forEach(doc => {
        const { name, price, description, image } = doc.data();
        businessList.push({
            key: doc.id,
            name, description,
            price, image
        });
    });
    return businessList;
};

const getDefaultImages = () => {
    return [
        { image: Carousel1 },
        { image: Carousel2 },
        { image: Carousel3 },
        { image: Carousel4 },
        { image: Carousel5 },
    ];
};

export const changeActiveImage = (index) => (dispatch) => {
    dispatch({
        type: CHANGE_ACTIVE_IMAGE,
        payload: index
    });
};