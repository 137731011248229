import{ thunk } from 'redux-thunk';
import {
    createStore, combineReducers, compose, applyMiddleware
} from 'redux';
import carouselReducer from './carouselDuck';
import propertyReducer from './propertyDuck';
import invesmentCreditReducer from './InvestmentCreditDuck';
import agentReducer from './agentDuck';
import contactUsReducer from './contactUsDuck';
import serviceReducer from './serviceDuck';
import aboutUsReducer from './aboutUsDuck';
import investorReducer from './investorClientDuck';

const rootReducer = combineReducers({
    carousel: carouselReducer,
    property: propertyReducer,
    invesmentCredit: invesmentCreditReducer,
    agent: agentReducer,
    contactUs: contactUsReducer,
    service: serviceReducer,
    aboutUs: aboutUsReducer,
    investor: investorReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
    const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
    return store;
};