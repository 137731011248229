import { firestore } from '../services/Firebase';
import { doc, onSnapshot } from "firebase/firestore";

const initialState = {
    aboutUsInfo: {
        visionEn: '',
        visionEs: '',
        misionEn: '',
        misionEs: '',
        historyEn: [],
        historyEs: [],
        addressEs: '',
        addressEn: '',
        emails: [],
        phones: [],
        loaded: false
    },
    loaded: false,
    error: null
};
var unsubscribe = null;

// types
const SUCCESS = 'SUCCESS';
const ERROR = 'ERROR';
const LOADING = 'LOADING';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING:
            return { ...state, loading: true, error: null };
        case SUCCESS:
            return { ...state, aboutUsInfo: action.payload, loading: false, loaded: true };
        case ERROR:
            return { ...state, error: true, loading: false };
        default:
            return state;
    }
};

// actions
export const getAbout = () => (dispatch) => {
    dispatch({ type: LOADING });
    try {
        if (unsubscribe)
            unsubscribe();
        const docRef = doc(firestore, "about", "about");
        unsubscribe = onSnapshot(docRef, docSnapshot => {
            dispatch({
                type: SUCCESS, payload: {
                    visionEn: docSnapshot.data()['vision-en'],
                    visionEs: docSnapshot.data()['vision-es'],
                    misionEn: docSnapshot.data()['mision-en'],
                    misionEs: docSnapshot.data()['mision-es'],
                    historyEn: docSnapshot.data()['history-en'].split('~'),
                    historyEs: docSnapshot.data()['history-es'].split('~'),
                    addressEs: docSnapshot.data()['address-es'],
                    addressEn: docSnapshot.data()['address-en'],
                    emails: docSnapshot.data()['emails'],
                    phones: docSnapshot.data()['phones'] ?? [],
                    loaded: true
                }
            });
        });
    } catch (error) {
        dispatch({ type: ERROR });
    }
};