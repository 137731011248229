import React from 'react';
import { Pagination } from 'react-instantsearch-dom';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import './PropertyPagination.css';

const PropertyPagination = () => {

    return (
        <Pagination
            padding={5}
            showFirst={false}
            showLast={false}
            translations={{
                previous: <ArrowBackIos fontSize='small' />,
                next: <ArrowForwardIos fontSize='small' />,
            }}
        />
    );
};

export default PropertyPagination;