import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getPropertyById } from '../../redux/propertyDuck';
import './PropertyDetail.css';

const PropertyDetail = () => {

    const propertyResult = useSelector(store => store.property.propertyData);
    const dispatch = useDispatch();
    const { id } = useParams();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        dispatch(getPropertyById(id));
    }, [dispatch, id]);

    return (
        propertyResult.features ? (
            <div className='property-detail-container'>
                <Grid item xs={12}>
                    <h4 className='property-detail-title'>{t('propertyDescriptionTitle')}</h4>
                    <ReactMarkdown className='property-detail-description'>
                        {i18n.language.includes('es') ? propertyResult.descriptionEs : propertyResult.descriptionEn}
                    </ReactMarkdown>
                    <h4 className='property-detail-title'>{t('propertyIdTitle')} {propertyResult.propertyId}</h4>
                    {
                        propertyResult.facebookLink &&
                        <a href={propertyResult.facebookLink} target="_blank" rel="noopener noreferrer">
                            Facebook
                        </a>
                    }
                    {/* <h4 className='property-detail-title'>Additional details</h4> */}
                    {/* <p className='property-detail-description'>{propertyResult.additionalDetails}</p> */}
                </Grid>
                <h4 className='property-detail-title'>{t('propertyFeaturesTitle')}</h4>
                <Grid container className='property-detail-description' spacing={2}>
                    {propertyResult.features.map((feature, index) =>
                        <Grid item xs={12} sm={4} md={3} key={index}>
                            <div> • {i18n.language.includes('es') ? feature.valueEs : feature.valueEn} </div>
                        </Grid>
                    )}
                </Grid>
            </div>
        ) : null
    );
};

export default PropertyDetail;