import { firestore, storage } from '../services/Firebase';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

// constants
const initialState = {
    activeStep: 0,
    isSended: false,
    loading: false,
    message: null
};

// types
const CREDIT_CHANGE_STEP = 'CREDIT_CHANGE_STEP';
const CREDIT_MESSAGE = 'CREDIT_MESSAGE';
const CREDIT_CANCEL_IS_SENDED = 'CREDIT_CANCEL_IS_SENDED';
const CREDIT_LOADING = 'CREDIT_LOADING';
const CREDIT_ERROR = 'CREDIT_ERROR';

// reducer
export default function invesmentCreditReducer(state = initialState, action) {
    switch (action.type) {
        case CREDIT_LOADING:
            return { ...state, loading: true, message: null };
        case CREDIT_CHANGE_STEP:
            return { ...state, activeStep: action.payload };
        case CREDIT_MESSAGE:
            return { ...state, message: action.payload, activeStep: 0, loading: false, isSended: true };
        case CREDIT_CANCEL_IS_SENDED:
            return { ...state, isSended: false };
        case CREDIT_ERROR:
            return { ...state, message: action.payload, loading: false };
        default:
            return state;
    }
};

export const addData = (credit) => async (dispatch) => {
    dispatch({ type: CREDIT_LOADING });
    try {
        const plan = await storage.ref(`investment-credits/${credit.email}/${uuidv4()}`)
            .put(credit.selectedPlanFile);
        const IdCard = await storage.ref(`investment-credits/${credit.email}/${uuidv4()}`)
            .put(credit.selectedIdFile);
        const planUrl = await plan.ref.getDownloadURL();
        const IdCardUrl = await IdCard.ref.getDownloadURL();
        const newCredit = {
            'email': credit.email,
            'phone-number': credit.phoneNumber,
            'currency-amount': credit.currencyAmount,
            'amount': parseFloat(credit.amount),
            'way-to-pay': credit.wayToPay,
            'plan-url': planUrl,
            'id-card-url': IdCardUrl,
            'active': true
        }
        await sendMail(newCredit);
        await firestore.collection('investment-credits').add(newCredit);
        dispatch({
            type: CREDIT_MESSAGE,
            payload: "Email sent successfully"
        });
    } catch (error) {
        dispatch({
            type: CREDIT_ERROR,
            payload: "ERROR TO SEND A MESSAGE, TRY AGAIN"
        });
    }
}

const sendMail = async (credit) => {
    const url = `${process.env.REACT_APP_CLOUDFUNCTIONS_URL}/investmentCreditMail`;
    await axios.post(url, {
        email: credit.email,
        phoneNumber: credit['phone-number'],
        amount: credit.amount,
        description: credit['way-to-pay'],
        planUrl: credit['plan-url'],
        idCardUrl: credit['id-card-url'],
        currency: credit['currency-amount']
    });
}

export const changeActiveStep = (step) => (dispatch) => {
    dispatch({
        type: CREDIT_CHANGE_STEP,
        payload: step
    });
};

export const isCreditSendedCancel = () => (dispatch) => {
    dispatch({ type: CREDIT_CANCEL_IS_SENDED });
};