import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
// import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
// import YouTubeIcon from '@material-ui/icons/YouTube';
import './Footer.css';

const Footer = () => {

    return (
        <Grid container className='footer-container'>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4} className='footer-icon-container'>
                <IconButton
                    component='a'
                    href={'https://www.facebook.com/profile.php?id=100054485290286&mibextid=ZbWKwL'}
                    target="_blank"
                    color='inherit'
                    rel="noopener noreferrer"
                >
                    <FacebookIcon fontSize='large' />
                </IconButton>
                {/* <InstagramIcon fontSize='large' style={{ margin: '0 20px' }} />
                <YouTubeIcon fontSize='large' /> */}
            </Grid>
            <Grid item xs={12} sm={4} className='footer-powered-container'>
                <span>Powered by JATCSolutions</span>
            </Grid>
        </Grid>
    );
};

export default Footer;