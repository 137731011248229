import { firestore } from '../services/Firebase';
import { onSnapshot, collection, query, where } from "firebase/firestore";

//constants
const initialState = {
    agents: [],
    loading: false,
    activeAgent: 0
};
var unsubscribe = null;

// types
const AGENT_SUCCESS = 'AGENT_SUCCESS';
const CHANGE_ACTIVE_AGENT = 'CHANGE_ACTIVE_AGENT';
const LOADING_AGENT = 'LOADING_AGENT';
const AGENT_ERROR = 'AGENT_ERROR';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_AGENT:
            return { ...state, loading: true, error: null };
        case AGENT_SUCCESS:
            return { ...state, agents: action.payload, loading: false, activeAgent: 0 };
        case AGENT_ERROR:
            return { ...state, error: action.payload, loading: false };
        case CHANGE_ACTIVE_AGENT:
            return { ...state, activeAgent: action.payload };
        default:
            return state;
    }
};

// actions
export const getAllAgents = () => (dispatch) => {
    dispatch({ type: LOADING_AGENT });
    try {
        if (unsubscribe)
            unsubscribe();
            const agentsRef = collection(firestore, "agents");
            const agentsQuery = query(agentsRef, where('active', '==', true))
        unsubscribe = onSnapshot(agentsQuery, querySnapshot => {
                dispatch(onCollectionUpdate(querySnapshot));
            });
    } catch (error) {
        console.log(error);
        dispatch({
            type: AGENT_ERROR,
            payload: 'Error al cargar los agentes'
        });
    }
};

const onCollectionUpdate = (querySnapshot) => (dispatch) => {
    const agents = [];
    querySnapshot.forEach(item => {
        agents.push({
            key: item.id,
            contact: item.data()['contact'],
            profileImage: item.data()['profile-image'],
            experienceEn: item.data()['experience-en'] ?? 'Not specified',
            experienceEs: item.data()['experience-es'] ?? 'No especificado',
            idiomsEs: item.data()['idioms-es'] ?? 'No especificado',
            idiomsEn: item.data()['idioms-en'] ?? 'Not specified',
            name: item.data()['name'],
            completeCode: item.data()['complete-code'],
        });
    });
    dispatch({
        type: AGENT_SUCCESS,
        payload: agents
    });
};
export const changeActiveAgent = (index) => (dispatch) => {
    dispatch({
        type: CHANGE_ACTIVE_AGENT,
        payload: index
    });
};