import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid, InputAdornment, Button,
    LinearProgress, Checkbox, InputLabel
    ,
    Snackbar
} from '@material-ui/core';
import TextFieldComponent from '../../components/TextField';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { sendMail, isSendedCancel } from '../../redux/contactUsDuck';
import * as ROUTES from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { getAbout } from '../../redux/aboutUsDuck';
import './ContactUs.css';

const ContactUs = () => {

    const loading = useSelector(store => store.contactUs.loading);
    const isSended = useSelector(store => store.contactUs.isSended);
    const [snackOpen, setSnackOpen] = useState(false);
    const [error, setError] = useState({});
    const [contact, setContact] = useState({
        email: '', firstName: '', lastName: '',
        phoneNumber: '', message: '', subscribe: false
    });
    const dispatch = useDispatch();
    const aboutUsInfo = useSelector(store => store.aboutUs.aboutUsInfo);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (!aboutUsInfo.loaded)
            dispatch(getAbout());
    }, [dispatch, aboutUsInfo]);

    const onChange = e => {
        const { name, value, checked } = e.target;
        const newValue = name === 'firstName' || name === 'lastName' ?
            value.replace(/\b\w/g, l => l.toUpperCase()) : value;
        setContact({ ...contact, [name]: name === 'subscribe' ? checked : newValue });
    };

    const validateMailData = () => {
        const contactErrors = {};
        const onlyLetters = /^[a-zñáéíóúüñ]*$/i;

        if (contact.email === null || contact.email.length === 0 ||
            /^\s+$/.test(contact.email))
            contactErrors.email = t('requiredFieldTitle');
        else if (!validateEmail(contact.email))
            contactErrors.email = t('invalidFormatEmail');
        if (contact.firstName === null || contact.firstName.length === 0 ||
            /^\s+$/.test(contact.firstName))
            contactErrors.firstName = t('requiredFieldTitle');
        else if (!contact.firstName.replace(" ", "").match(onlyLetters))
            contactErrors.firstName = t('onlyLettersAllowedFirstName');
        if (contact.lastName === null || contact.lastName.length === 0 ||
            /^\s+$/.test(contact.lastName))
            contactErrors.lastName = t('requiredFieldTitle');
        else if (!contact.lastName.replace(" ", "").match(onlyLetters))
            contactErrors.lastName = t('onlyLettersAllowedLastName');
        if (!(contact.phoneNumber === null ||
            contact.phoneNumber.length === 0 ||
            /^\s+$/.test(contact.phoneNumber)) &&
            !(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
                .test(contact.phoneNumber.replace(/ /g, ''))))
            contactErrors.phoneNumber = t('phoneInvalidFormat');
        if (contact.message === null || contact.message.length === 0 ||
            /^\s+$/.test(contact.message))
            contactErrors.message = t('requiredFieldTitle');
        else if (contact.message.length < 10)
            contactErrors.message = t('minimumMessageLength');
        return contactErrors;
    };

    const validateEmail = ((email) => {
        var regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email) ? true : false;
    });

    const onSubmit = () => {
        const mailErrors = validateMailData();
        setError(mailErrors);
        if (Object.entries(mailErrors).length === 0)
            dispatch(sendMail(contact));
    };

    useEffect(() => {
        if (isSended) {
            setSnackOpen(true);
            setContact({
                email: '', firstName: '', lastName: '',
                phoneNumber: '', message: '', subscribe: false
            });
            dispatch(isSendedCancel());
        }
    }, [isSended, dispatch]);

    const handleCloseSnackbar = (e, reason) => {
        if (reason === 'clickaway') return;
        setSnackOpen(false);
    }

    return (
        <div id={ROUTES.CONTACT_US.replace('#', '')}>
            <Grid container className='container-contact-us'>
                <Grid item xs={12} sm={6} md={4} className='item-contact-us'>
                    <div style={{ width: '90%' }}>
                        <InputLabel className='title-items'>{t('emailTitle')}</InputLabel>
                        <TextFieldComponent name='email'
                            valueInput={contact.email}
                            callback={onChange.bind(this)}
                            errorInput={error.email}
                            placeholder='example@gmail.com'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <InputLabel className='title-items'>{t('firstNameTitle')}</InputLabel>
                        <TextFieldComponent name='firstName'
                            valueInput={contact.firstName}
                            callback={onChange.bind(this)}
                            errorInput={error.firstName}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <PersonIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <InputLabel className='title-items'>{t('lastNameTitle')}</InputLabel>
                        <TextFieldComponent name='lastName'
                            valueInput={contact.lastName}
                            callback={onChange.bind(this)}
                            errorInput={error.lastName}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <PersonIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className='item-contact-us'>
                    <div style={{ width: '90%' }}>
                        <InputLabel className='title-items'>{t('phoneTitle')}</InputLabel>
                        <TextFieldComponent name='phoneNumber'
                            valueInput={contact.phoneNumber}
                            callback={onChange.bind(this)}
                            errorInput={error.phoneNumber}
                            placeholder='+506 8888-8888'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <PhoneIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <InputLabel className='title-items'>{t('messageTitle')}</InputLabel>
                        <TextFieldComponent name='message'
                            valueInput={contact.message}
                            callback={onChange.bind(this)}
                            errorInput={error.message}
                            className='contact-us-textarea'
                            row='2'
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} className='item-contact-us'>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                        <InputLabel className='title-items'>{t('contactInfoTitle')}</InputLabel>
                        <div className='div-contact-us-info'>
                            <LocationOnIcon style={{ marginRight: 18 }} />
                            {i18n.language.includes('es') ? aboutUsInfo.addressEs : aboutUsInfo.addressEn}
                        </div>
                        <div className='div-contact-us-info' style={{ alignItems: 'flex-start' }}>
                            <PhoneOutlinedIcon style={{ marginRight: 18, marginTop: 14 }} />
                            <div>
                                {
                                    aboutUsInfo.phones.map((phone, index) => (
                                        <p key={index}>{phone}</p>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='div-contact-us-info'style={{ alignItems: 'flex-start' }}>
                            <MailOutlineIcon style={{ marginRight: 18, marginTop: 14}} />
                            <div>
                                {
                                    aboutUsInfo.emails.map((email, index) => (
                                        <p key={index}>{email}</p>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className='item-contact-us'>
                    <div style={{ width: '90%' }}>
                        <div className='div-contact-us-info'>
                            <Checkbox
                                name='subscribe'
                                checked={contact.subscribe}
                                onChange={onChange}
                                style={{ color: '#000', padding: 0, paddingRight: 19 }}
                            />
                            <p className='subscribe-p'>{t('subscribeToNewsletterTitle')}</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className='item-contact-us'>
                    <div style={{ width: '90%' }}>
                        {loading
                            ? <LinearProgress className='loading-contact-us' />
                            :
                            <Button variant='contained' className='contact-us-send-button'
                                onClick={() => onSubmit()}
                            >
                                {t('sendTitle')}
                            </Button>
                        }
                    </div>
                </Grid>
                <Snackbar open={snackOpen}
                    autoHideDuration={4000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    message={<span id='message-id'>Email sent successfully</span>}
                />
            </Grid>
        </div>
    );
};

export default ContactUs;