import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Grid, Button, MobileStepper } from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import PropertyInfo from './PropertyInfo';
import { changeActiveImage, getPropertiesCarousel } from '../../redux/carouselDuck';
import './Carousel.css';

const CarouselImage = () => {

    const interval = 7000;
    const properties = useSelector(store => store.carousel.properties);
    const defaultImages = useSelector(store => store.carousel.defaultImages);
    const activeImage = useSelector(store => store.carousel.activeImage);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPropertiesCarousel());
    }, [dispatch]);

    const nextImage = () => {
        const arraySize = properties.length > 0 ? properties.length : defaultImages.length;
        const maxIndex = arraySize - 1;
        if (maxIndex > activeImage)
            dispatch(changeActiveImage(activeImage + 1));
        else if (maxIndex > 0)
            dispatch(changeActiveImage(0));
    };

    const backImage = () => {
        const arraySize = properties.length > 0 ? properties.length : defaultImages.length;
        const maxIndex = arraySize - 1;
        if (0 < activeImage)
            dispatch(changeActiveImage(activeImage - 1));
        else if (maxIndex > 0)
            dispatch(changeActiveImage(maxIndex));
    };

    useEffect(() => {
        const timer = setTimeout(() => nextImage(), interval);
        return () => clearTimeout(timer);
    });

    return (
        <Grid container>
            <Grid item xs={12} id={activeImage} className='carousel-home-conatiner'>
                {properties.length > 0 ?
                    properties.map((property, index) => (
                        <LazyLoadImage
                            key={index} alt=''
                            src={property.image}
                            className={`${index === activeImage && 'active'} carousel-home-image`}
                            style={{ display: index === activeImage ? 'block' : 'none' }}
                        />
                    ))
                    : defaultImages.map((image, index) => (
                        <LazyLoadImage
                            key={index} alt=''
                            src={image.image}
                            className={`${index === activeImage && 'active'} carousel-home-image`}
                            style={{ display: index === activeImage ? 'block' : 'none' }}
                        />
                    ))}
                <Button className='carousel-home-back-button'
                    onClick={() => backImage()}
                >
                    <ArrowBackIos />
                </Button>
                <Button className='carousel-home-next-button'
                    onClick={() => nextImage()}
                >
                    <ArrowForwardIos />
                </Button>
                <Grid item xs={12} className='carousel-home-stepper-container'>
                    <MobileStepper variant='dots' steps={properties.length > 0 ? properties.length : defaultImages.length} position='static'
                        activeStep={activeImage} className='carousel-home-stepper'
                    />
                </Grid>
                <PropertyInfo property={properties.length > 0 ? properties[activeImage] : null} />
            </Grid>
        </Grid>
    )
}

export default CarouselImage;